jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/accordion.js');
  require('./components/slider.js');
  require('./components/team-popup.js');
  require('./components/video.js');

  loadMorePosts.init();
  loadMoreGames.init();
});


// Load More Posts
var loadMorePosts = {
	init: function() {
    jQuery('#load-more').click(function(){
      loadMorePosts.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_posts',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more').replaceWith('<a class="button no-posts" href="#load-container">Back to top</a>');
				}

				return;
			}
		});
	}
}


// Load More Games
var loadMoreGames = {
	init: function() {
    jQuery('#load-more-games').click(function(){
      loadMoreGames.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-games-container');
		var postCount = jQuery('#load-games-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_games',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more-games').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more-games').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-games-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more-games').replaceWith('<a class="button no-posts" href="#load-container">Back to top</a>');
				}

				return;
			}
		});
	}
}


// Alert Bar
const alertBar1 = document.querySelector('#alert-bar-1');
const alertBar2 = document.querySelector('#alert-bar-2');
let alertBarStorage = false;

alertBar2.addEventListener('click', function() {
  alertBar1.style.display = 'none';
  alertBar2.style.display = 'none';

  alertBarStorage = true;
  sessionStorage.setItem('alert', alertBarStorage);
});

let removeAlert = (sessionStorage.getItem('alert'));

if(removeAlert) {
  alertBar1.style.display = 'none';
  alertBar2.style.display = 'none';
}

window.addEventListener("load", function() {
  document.querySelector('#alert-bar-1').classList.add('load-active');
  document.querySelector('#alert-bar-2').classList.add('load-active');
});


// Menu Hover
const menuHover = function() {
  const menues = document.querySelectorAll('.menu-item-has-children .sub-menu');

  menues.forEach(function(menu) {
    menu.addEventListener('mouseover', function hover() {
      this.parentElement.classList.add('sub-menu-hover');
    });

    menu.addEventListener('mouseleave', function leave() {
      this.parentElement.classList.remove('sub-menu-hover');
    });
  });
}

let mq = window.matchMedia( "(max-width: 1024px)" );

if(mq.matches) {
} else {
  menuHover();
}